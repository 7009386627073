.landing-component {
  overflow-x: hidden;
  .landing-big-title {
    text-transform: uppercase;
    font-size: 5rem;
    font-weight: 900;
    letter-spacing: 5px;
    padding: 0px;
    padding-top: 50px;
    transform: translateX(-7px);
    @media (max-width: 760px) {
      font-size: 3rem;
      letter-spacing: 1px;
    }
  }
}
